@use 'styles/config' as *;
@use "sass:math";

.error {
  @include segment-top(20rem, 10rem);
  @include segment-bottom(20rem, 10rem);
  @include nav-offset-margin();

  position: relative;
  height: 56rem;
  min-height: 100vh;

  background: $color-dark no-repeat 100% 100%;
  background-image: linear-gradient(
      to bottom,
      rgba($color-dark, 0.7) 0%,
      rgba($color-dark, 0.7) 100%
    ),
    url('/static/images/404.png');
  background-size: cover;

  // @media (min-width: $min-600) {
  //   height: 100vh;
  // }

  @media (min-width: $min-960) {
    background-image: url('/static/images/404.png');
  }

  &__container {
    @include container();

    display: flex;
    align-items: center;

    height: 100%;
  }

  &__content {
    width: 100%;
  }

  &__row {
    @include grid-row;
  }

  &__column {
    @include grid-col();

    @media (min-width: $min-840) {
      @include grid-col(8);
    }

    @media (min-width: $min-960) {
      @include grid-col(6);
    }
  }

  &__tagline {
    @include taglineWithLine;
    margin: 0 0 4rem;
  }

  &__title {
    @include h1;

    margin: 0;

    :global(body.dark) & {
      color: #fff;
    }
  }

  &__text {
    margin-top: 4.5rem;

    font-size: 1.8rem;
    font-weight: 300;
    line-height: math.div(30, 18);
    color: $color-copy;

    :global(body.brand) & {
      color: $color-font;
    }

    :global(body.dark) & {
      color: #fff;
    }

    @include dropCap();
  }

  &__button {
    margin-top: 4rem;

    @media (min-width: $min-720) {
      margin-top: 6rem;
    }
  }

  &__image {
    position: absolute 0;
    width: 100%;
    height: 100%;

    object-fit: contain;

    @media (min-width: $min-960) {
      position: absolute 0;
    }
  }

  &__background {
    position: relative;
    margin-top: 6rem;
    background: #555;

    @media (min-width: $min-960) {
      position: absolute 0;
      margin-top: 0;
      left: 50%;
    }
  }
}
